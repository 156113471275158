import { FC, ReactNode } from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { roadie } from 'styles/themes'

type Theme = {
  [k: string]: any
}

const ColorScheme:FC<{light: Theme, children:ReactNode}> = props => {
  const { light } = props

  return (
    <ThemeProvider theme={light}>
      {props.children}
    </ThemeProvider>
  )
}

const RoadieThemeProvider:FC<{children:ReactNode}> = props => (
  <ColorScheme light={roadie}>
    {props.children}
  </ColorScheme>
)

export default RoadieThemeProvider