import snakeCase from 'lodash/snakeCase'
import { Color, isColorIndicator, MainTheme, ShadedColorName } from 'roadie-ui'
import { CustomTrackTheme, Theme } from 'rui/track/types'

const themeUtil = {
    image,
    url,
}
export default themeUtil

export function convertTrackThemeToRoadieUITheme( theme: CustomTrackTheme ): Theme {
    const { colors, ...themeBody } = theme
    const themeColors = colors ?? {}

    const roadieUItemp: Theme['RoadieUI'] = {
        parent: MainTheme,
        colors: {}
    }

    const newTheme: CustomTrackTheme = {
        ...themeBody,
        logoMobile: themeBody.logoMobile ?? undefined,

        colors: {}
    }

    type Shades = {
        lighter?: string
        light?: string
        base?: string
        dark?: string
        darker?: string
    }
    const indicatorsFound: Partial<Record<ShadedColorName, Shades>> = {}

    const colorNames = Object.keys(themeColors)

    colorNames.forEach(colorName => {
        const indicator = snakeCase(colorName).replace(/_/g, '.').replace('.base', '')
        if (isColorIndicator(indicator)) {
            const [_color , _shade='base'] = indicator.split('.', 2)
            const color = _color as ShadedColorName
            const shade = _shade as keyof Shades
            if (!indicatorsFound[color]) {
                indicatorsFound[color] = {}
            }
            indicatorsFound[color]![shade] = themeColors[colorName]
        } else {
            newTheme.colors![colorName] = themeColors[colorName]
        }
    })

    const indicatorNames = Object.keys(indicatorsFound) as ShadedColorName[]

    indicatorNames.forEach(indicatorName => {
        const shades = indicatorsFound[indicatorName] ?? {}
        const base = shades.base || shades.dark || shades.light || shades.darker || shades.lighter || ''
        const light = shades.light || base
        const lighter = shades.lighter || base
        const dark = shades.dark || base
        const darker = shades.darker || base
        const newColor = Color.define(indicatorName, base, {
            light,
            dark,
            lighter,
            darker,
        })

        roadieUItemp.colors[indicatorName] = newColor
    })
    const newRoadieUITheme = newTheme as Theme
    newRoadieUITheme.RoadieUI = roadieUItemp

    console.log('newTheme', newTheme)

    return newRoadieUITheme
}

const DEFAULT_THEME = 'roadie'
const THEME_FOLDER_PARAM = 'themeFolder'

type ThemeFolder = 'qa' | 'prod'
/*
  Empty string means that it is an empty value while undefined means uncached
 */
let cachedThemeFolder: ThemeFolder | '' | undefined = undefined
let cachedThemeBucket: string | undefined = undefined

function image(themeName= DEFAULT_THEME, image: string): string {
    return joinPaths(url(themeName), image)
}

function url(themeName= DEFAULT_THEME): string {
    const themeFolder = getThemeFolder()
    const themeBucket = process.env.REACT_APP_THEME_BUCKET!
    
    if (!themeFolder) {
        return joinPaths(themeBucket, themeName)
    }
    
    if (!themeBucket.endsWith('qa') && !themeBucket.endsWith('prod')) {
        return joinPaths(themeBucket, themeName)
    }
    
    if (cachedThemeBucket) {
        return joinPaths(cachedThemeBucket, themeName)
    }
    
    const lastSlash = themeBucket.lastIndexOf('/')
    const themeBucketRoot = themeBucket.substring(0, lastSlash)
    cachedThemeBucket = joinPaths(themeBucketRoot, themeFolder)
    return joinPaths(cachedThemeBucket, themeName)
}

function getThemeFolder() {
    if (!process.env.REACT_APP_CAN_USE_CUSTOM_THEME_FOLDER) {
        return cachedThemeFolder
    }
    
    if (cachedThemeFolder !== undefined) {
        return cachedThemeFolder
    }
    
    const urlParameters = new URLSearchParams(window.location.search)
    const themeFolderParameter = urlParameters.get(THEME_FOLDER_PARAM)
    
    if (!paramIsThemeFolder(themeFolderParameter)) {
        cachedThemeFolder = ''
    } else {
        cachedThemeFolder = themeFolderParameter
    }

    return cachedThemeFolder
}

function paramIsThemeFolder(param: string | null): param is ThemeFolder {
    return param === 'qa' || param === 'prod'
}

function joinPaths(...paths: string[]): string {
    return paths.join('/')
}