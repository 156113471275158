import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Container, Spacer, Text, Loader, MapSystem } from 'roadie-ui'
import TrackingProgressBar from 'rui/track/TrackingProgressBar'
import TrackingHistory from 'rui/track/TrackingHistory'
import TrackingDeliveryInfo from './TrackingDeliveryInfo'
import TrackingFooter from './TrackingFooter'
import TrackForm from './Form'
import { Time } from 'roadie-ui'
import { fetchTrackingData } from 'data/track'
import { Theme, TrackingApiData } from './types'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import { useTheme } from 'styled-components'
import themeUtil from 'util/theme'
import { SUPPORT_PHONE_NUMBER } from 'util/constants'
import { formatPhone } from 'util/format'


const TrackingPage:FC<{themeName:string}> = props => {
  const { themeName } = props
  const theme = useTheme() as Theme
  const [trackingInfo, setTrackingInfo] = useState<TrackingApiData | null>(null)
  const prevTracking = useRef<TrackingApiData | null>(null)
  const [updating, setUpdating] = useState(true)
  const [failed, setFailed] = useState(false)
  const dispatch = useDispatch()
  const { tracking:code } = useParams() as { tracking: string }

  const fetchTracking = useCallback(() => {
    if (!trackingInfo) {
      setUpdating(true)
    }
    fetchTrackingData(code).then(trackingData => {
      // Only set and re-render if has changed since last fetch
      if (!isEqual(prevTracking.current, trackingData)) {
        setTrackingInfo(trackingData)
        // A reference to the previous tracking data that won't trigger a refetch when changed
        prevTracking.current = trackingData
      }
      dispatch({ type: 'CLEAR_FLASH', id: 'tracking' })
    }).catch(() => {
      dispatch({ type: 'PUSH_FLASH', flash: {
        id: 'tracking',
        type: 'error',
        message: !!(prevTracking.current) ? 'Network Error. Could not load updates.' : 'Delivery not found. Please confirm that your tracking number is correct.'
      }})
      if (!prevTracking.current) {
        setFailed(true)
      }
    }).finally(() => {
      setUpdating(false)
    })
  }, [trackingInfo, code, dispatch])
  
  useEffect(() => {
    if (!failed) {
      fetchTracking()
      const int = setInterval(fetchTracking, 1000 * 15)
      return () => clearInterval(int)
    }
  }, [fetchTracking, failed])

  if (!trackingInfo) {
    if (updating) {
       // Initial Load
       return <Loader mode="fixed" />
    } else {
      // Tracking not found
      return <TrackForm themeName={themeName} badCode={code} />
    }
  }

  const trackingEvents = trackingInfo.events.map(e  => e.event_type)
      
  const wasPickedUp = trackingEvents.includes('pickup_confirmed')
  const hasStartedRoute = trackingEvents.includes('roadie_on_way_to_delivery')
  const isDelivered = trackingEvents.includes('delivery_confirmed')
  const isCancelled = trackingEvents.includes('gig_cancelled')

  const pickedUpAt = wasPickedUp ? Time.fromDate(new Date(trackingInfo.events.find(e => e.event_type === 'pickup_confirmed')!.created_at)) : undefined
  const startedRouteAt = hasStartedRoute ? Time.fromDate(new Date(trackingInfo.events.find(e => e.event_type === 'roadie_on_way_to_delivery')!.created_at)) : undefined
  const deliveredAt = isDelivered ? Time.fromDate(new Date(trackingInfo.events.find(e => e.event_type === 'delivery_confirmed')!.created_at)) : undefined
  const cancelledAt = isCancelled ? Time.fromDate(new Date(trackingInfo.events.find(e => e.event_type === 'gig_cancelled')!.created_at)) : undefined

  const expired = trackingInfo.state === 'expired'
  const attempted = trackingInfo.state === 'delivery_attempted'
  const hasFailed = isCancelled || expired || attempted
  const failedReason = isCancelled ? 'Canceled' : expired ? 'Expired' : attempted ? 'Delivery Attempted' : 'Error'
  const live = !isDelivered && !hasFailed
  const concludedAt = live ? undefined : isDelivered ? deliveredAt : isCancelled ? cancelledAt : Time.fromDate(new Date(trackingInfo.updated_at))

  const showingRoadieDriver = !!(
    live &&
    trackingInfo.roadie &&
    trackingInfo.roadie.location &&
    !trackingInfo.events.find(e => e.event_type === 'delivery_confirmed')
  )

  return (
    <Container.Base $width="100%">
      <Container.Grid
        $gridTemplateColumnsMedia={{desktop:"97.7rem", mobile:"100%", tablet:"100%"}}
        $justifyContent="center"
      >
        <Spacer $height="4rem" />
        <TrackingProgressBar
          wasPickedUp={wasPickedUp}
          pickedUpAt={pickedUpAt}
          
          hasStartedRoute={hasStartedRoute}
          startedRouteAt={startedRouteAt}
          
          isDelivered={isDelivered}
          deliveredAt={deliveredAt}
          
          hasFailed={hasFailed}
          failedReason={failedReason}
          
          deliveryDeadline={Time.fromDate(new Date(trackingInfo.delivery_deadline))}
          
          pickupLocation={`${trackingInfo.pickup_location.city}, ${trackingInfo.pickup_location.state}`}
          deliveryLocation={`${trackingInfo.delivery_location.city}, ${trackingInfo.delivery_location.state}`}
        />
        <Spacer $height="4rem" />
        <MapSystem.Map height="52.5rem" width="100%">
          <MapSystem.Marker
            centerInMap={!showingRoadieDriver}
            fitInMap={showingRoadieDriver}
            latitude={trackingInfo.delivery_location.latitude}
            longitude={trackingInfo.delivery_location.longitude}
            imageUrl={theme.map.markers.end ? themeUtil.image(themeName, theme.map.markers.end) : undefined}
          >
            <Text.Body1 $color="!black">
              {trackingInfo.delivery_location.street}<br />
              {trackingInfo.delivery_location.city}, {trackingInfo.delivery_location.state} {trackingInfo.delivery_location.zip}
            </Text.Body1>
          </MapSystem.Marker>
          {showingRoadieDriver ? (
            <MapSystem.Marker
              fitInMap
              latitude={trackingInfo.roadie!.location.latitude}
              longitude={trackingInfo.roadie!.location.longitude}
              imageUrl={theme.map.markers.location ? themeUtil.image(themeName, theme.map.markers.location) : undefined}
              size={62}
            />
          ) : null}
        </MapSystem.Map>
        <Spacer $height="3.6rem" />
        <Container.Grid
          $gridTemplateColumns="1.5fr 1fr"
          $gridTemplateColumnsMedia={{mobile:'1fr'}}
          $gridGap="3rem"
        >
          <Container.Base>
            <Container.Base $paddingMedia={{mobile: '0 0 0 1rem'}}>
              <Text.Overline>Tracking Updates</Text.Overline>
            </Container.Base>
            <Spacer $height=".8rem" />
            <TrackingHistory code={code} tracking={trackingInfo} />
          </Container.Base>
          <Container.GridItem $orderMedia={{mobile: "-1"}}>
            <Container.Base $paddingMedia={{mobile: '0 0 0 1rem'}}>
              <Text.Overline>Delivery Information</Text.Overline>
            </Container.Base>
            <Spacer $height=".8rem" />
            <TrackingDeliveryInfo concludedAt={concludedAt} tracking={trackingInfo} code={code} />
          </Container.GridItem>
        </Container.Grid>
      </Container.Grid>
      <Spacer $height="4rem" />
      <Container.Grid
        $gridTemplateColumnsMedia={{desktop:"97.7rem", mobile:"100%", tablet:"100%"}}
        $justifyContent="center"
        $backgroundColor="lookup:footerbg"
        $padding="3.2rem"
      >
        <TrackingFooter 
          faqTitle={theme.faqTitle ?? 'Common Questions'}
          faq={theme.faq} 
          helpTitle={theme.helpTitle ?? 'Still Need Help?'}
          helpNumber={theme.helpNumber ?? {
            label: formatPhone(SUPPORT_PHONE_NUMBER),
            value: `tel:${SUPPORT_PHONE_NUMBER}`
          }}
        />
      </Container.Grid>
    </Container.Base>
  )
}

export default TrackingPage