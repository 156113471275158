export function sendNativeMessage(data) {

  console.log('sending', data)
  
  const message = JSON.stringify(data)

  try {
    window.webkit.messageHandlers.nativeAction.postMessage(message)
    console.log('sent on ios')
  } catch (e) {
    console.log('not sent on ios', e)
  }

  try {
    window.nativeAction.postMessage(message)
    console.log('sent on anbdroid')
  } catch (e) {
    console.log('not sent on android', e)
  }

  console.log('done sending', data)
}

window.sendNativeMessage = sendNativeMessage