import React from 'react'
import { Container, Text } from 'roadie-ui'

type LightboxCloseProps = {
    onClick : React.MouseEventHandler
}

const LightboxClose:React.FC<LightboxCloseProps> = ({ onClick }) => (
    <Container.Position
        onClick={onClick}
        $position="absolute"
        $right="0"
        $top="0"
        $width="4rem"
        $height="4rem"
        $color="!white"
        $backgroundColor="!black@.75"
        $overflow="hidden"
        $cursor="pointer"
        $borderRadius="0 0 0 .5rem"
        $textAlign="center"
    >
        <Text.Button $fontSize="3.5rem" $fontWeight="bold">×</Text.Button>
    </Container.Position>
)

export default LightboxClose