import React from 'react'
import { Link } from 'react-router-dom'
import styled, { withTheme, css } from 'styled-components/macro'
import { lighten } from 'polished'
import { button } from  'styles/mixins/textstyles'
import media from 'styles/mixins/media'
import { isTrackURL } from 'util/track'
import { ExternalLink } from 'components/layout/links'
import MainLayout from 'rui/widgets/MainLayout'
import { Container, hooks } from 'roadie-ui'
import themeUtil from "util/theme";

const TrackAnotherLink = styled(Link)`
  ${button()}
  padding: 2.3rem 3.5rem;
  color: ${props => props.theme.colors[props.theme.headerfg] || props.theme.headerfg || props.theme.colors.white};

  &:hover {
    color: ${props => lighten(0.1, props.theme.colors[props.theme.headerfg] || props.theme.headerfg || props.theme.colors.white)};
  }

  ${media.mobile`
    display: none;
  `}
`

const LogoLink = styled(ExternalLink)`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0;

  ${media.mobile`
    padding-left: 1.5rem;
  `}
`

const ThemeLogo = styled.img`
  height: ${props => props.height || '4.6rem'};
  ${props => props.width && css`
    width: ${props.width};
  `}
`

function getTrackAnotherLink(props) {
  const { themeName, match: { path } } = props
  if (path === '/' || path === '/:theme') {
    return null
  } else {
    return (
      <TrackAnotherLink to={isTrackURL() && themeName ? `/${themeName}` : '/'}>Track Another Delivery</TrackAnotherLink>
    )
  }
}

const TrackHeader = props => {
  const { theme } = props
  const isMobile = hooks.useIsMediaSize('mobile')
  const logo = isMobile && theme.logoMobile ? theme.logoMobile : theme.logo

  return (
    <Container.Grid
    $backgroundColor="lookup:headerbg"
    $alignItems="center"
    $justifyContent="stretch"
    $justifyItems="center"
    >
    <Container.Grid
      $height="6.4rem"
      $width="100%"
      $maxWidth="102.5rem"
      $justifyContent="space-between"
      $gridTemplateColumns="auto auto"
      $alignItems="center"
      $padding="0 1.5rem"
      $paddingMedia={{mobile: '0'}}
    >
      <LogoLink href={logo.link}>
        <ThemeLogo height={logo.height} width={logo.width} src={themeUtil.image(props.themeName, logo.src)} />
      </LogoLink>
      {getTrackAnotherLink(props)}
    </Container.Grid>
    <Container.Base $height="1px" $width="100%" $backgroundColor="lookup:headerborder" />
  </Container.Grid>
  )
}

const TrackLayout = props => (
  <MainLayout
    fullscreen={true}
    header={<TrackHeader {...props} />}
    footer={false}
  >
    {props.children}
  </MainLayout>
)

export default withTheme(TrackLayout)