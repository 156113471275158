import React from 'react'
import styled from 'styled-components/macro'
import { Overline } from 'components/layout/text'
import Icon from 'components/misc/Icon'
import { ExternalLink } from 'components/layout/links'

const LogoText = styled(Icon).attrs(props => ({ symbol: 'logo-text', palette: 'grey', shade: 'darker' }))`
  height: 1.2rem;
  width: 8rem;
`

const Wrapper = styled(ExternalLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Text = styled(Overline)`
  font-size: 1rem;
  color: ${props => props.theme.colors.greyDark};
  margin-right: .4rem;
`

const PoweredByRoadie = () => (
  <Wrapper href="https://roadie.com" target="_blank">
    <Text>Delivery By</Text>
    <LogoText />
  </Wrapper>
)

export default PoweredByRoadie