import React from 'react'
import { Image } from 'roadie-ui'
 
type AvatarProps = {
  url: string,
  size?: number | string,
  id?: string
}

const Avatar:React.FC<AvatarProps> = (props) => (
  <Image
    id={props.id}
    src={props.url}
    $width={`${(Number(props.size) || 50) / 10}rem`}
    $height={`${(Number(props.size) || 50) / 10}rem`}
    $borderRadius="50%"
    $objectFit="cover"
  />
)

export default Avatar