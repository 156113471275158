const colors = {

  white: 'white',
  black: 'black',

  // PRIMARY

  primaryLighter: '#E6F8F8',
  primaryLight: '#80DDDD',
  primaryBase: '#00BBBB',
  primaryDark: '#008383',
  primaryDarker: '#003838',

  // SECONDARY

  secondaryLighter: '#E7EDFA',
  secondaryLight: '#B7C8F1',
  secondaryBase: '#5680DF',
  secondaryDark: '#2F4880',
  secondaryDarker: '#04153D',
  
  // GREYSCALE

  greyLighter: '#EAEAEA',
  greyLight: '#D2D2D2',
  greyBase: '#A8A8A8',
  greyDark: '#7A7A7A',
  greyDarker: '#4C4C4C',


  // SEMANTIC

  infoLighter: '#d3e8fe',
  infoLight: '#92c5fd',
  infoBase: '#258afb',
  infoDark: '#1a61b0',
  infoDarker: '#0b294b',

  successLighter: '#e3f2da',
  successLight: '#b9dfa2',
  successBase: '#72bf44',
  successDark: '#508630',
  successDarker: '#223914',
  
  alertLighter: '#fff1d0',
  alertLight: '#fedc89',
  alertBase: '#fdb913',
  alertDark: '#b1820d',
  alertDarker: '#4c3706',

  errorLighter: '#f9d9d3',
  errorLight: '#f1a092',
  errorBase: '#e34025',
  errorDark: '#9f2d1a',
  errorDarker: '#44130b',


  // OTHER

  star: '#efcd14',
  facebookBlue: '#3B5998',
  background: '#f7f7f7',
}

const dark = {
  ...colors,

  black: 'white',
  white: 'black',
  background: '#222222',
}

function invert(a, b, key) {
  a[`${key}Light`] = b[`${key}Dark`]
  a[`${key}Lighter`] = b[`${key}Darker`]
  a[`${key}Dark`] = b[`${key}Light`]
  a[`${key}Darker`] = b[`${key}Lighter`]
}

invert(dark, colors, 'primary')
invert(dark, colors, 'secondary')
invert(dark, colors, 'grey')
invert(dark, colors, 'info')
invert(dark, colors, 'success')
invert(dark, colors, 'alert')
invert(dark, colors, 'error')

export { dark }

export default colors