import { css } from 'styled-components/macro'
import { rgba } from 'polished'

const NORMAL = 1
const MEDIUM = 0.6
const LOW = 0.3

const emphasisLevels = { NORMAL, MEDIUM, LOW }

function emphasis(color, level) {
  try {
    return `
      color: ${rgba(color, emphasisLevels[level.toUpperCase()])};
    `
  } catch {
    return `
      color: ${color};
      opacity: ${emphasisLevels[level.toUpperCase()]};
    `
  }
}

const textStyle = (...args) => (color, level='normal') => {
  return `
    ${css(...args)}

    ${color ? emphasis(color, level) : ''}
  `
}

export const h1 = textStyle`
  font-size: 1.04rem;
  font-weight: 300;
  letter-spacing: -.15rem;
`

export const h2 = textStyle`
  font-size: 6.4rem;
  font-weight: 300;
  letter-spacing: -0.05rem;
`

export const h3 = textStyle`
  font-size: 4.8rem;
  font-weight: 300;
`

export const h4 = textStyle`
  font-size: 3.2rem;
  line-height: normal;
  letter-spacing: 0.025rem;
  font-weight: bold;
`

export const h5 = textStyle`
  font-size: 2.6rem;
  font-weight: 300;
`

export const h6 = textStyle`
  font-size: 2.2rem;
  line-height: normal;
  letter-spacing: 0.025rem;
  font-weight: 500;
`

export const subtitle1 = textStyle`
  font-size: 1.8rem;
  line-height: normal;
  letter-spacing: 0.015rem;
  font-weight: normal;
`

export const subtitle2 = textStyle`
  font-size: 1.8rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
`

export const body1 = textStyle`
  font-size: 1.8rem;
  line-height: normal;
  letter-spacing: normal;
  font-weight: normal;
`

export const body2 = textStyle`
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: .025rem;
  font-weight: normal;
`

export const button = textStyle`
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: .125rem;
  font-weight: 500;
  text-transform: uppercase;
`

export const caption = textStyle`
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: .04rem;
  font-weight: 400;
`

export const overline = textStyle`
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: .2rem;
  font-weight: 500;
  text-transform: uppercase;
`