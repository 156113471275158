import updateTree from './updateTree'

export default function earningsStatement(state = {}, action) {
  switch (action.type) {

    case 'SET_EARNINGS_STATEMENT': {
      return updateTree(state, 'earningsStatement', () => action.earningsStatement)
    }

    default:
      return {
        earningsStatement: [],
        ...state
      }
  }
}