import styled, { css } from 'styled-components'
import * as textstyles from 'styles/mixins/textstyles'

// ### USAGE
//
// Each const defines a component with a textstyle and a default color/emphasis
// However, each component will have a color and emphasis property to override
// the default.
//
//     // uses the h1 textstyle with the default color/emphasis:
//
//     <H1>foo</H1>
//
// The color property can be a valid css color name or the name of a color on the theme colors.
//
//     // uses the h1 textstyle with a low empahsis and the primary base of the current theme:
//
//     <H1 color="primaryBase" emphasis="low">foo</H1>

function ts(fn, color, emphasis) {
  return css`
    ${props => fn(props.theme.colors[props.color] || props.color || props.theme.colors[color] || color, props.emphasis || emphasis)}
    display: ${props => props.inline ? 'inline' : 'block'};
    ${props => props.weight && css`
      font-weight: ${props.weight};
    `}
    ${props => props.align && css`
      text-align: ${props.align};
    `}
    ${props => props.marginBottom && css`
      margin-bottom: ${props.marginBottom};
    `}
    ${props => props.pre && css`
      white-space: pre-wrap;
    `}
    ${props => props.onClick && css`
      &:hover {
        cursor: pointer;
      }
    `}
    ${props => props.fontSize && css`
      font-size: ${props.fontSize};
    `}
  `
}

export const H1 = styled.div`${ts(textstyles.h1, 'black', 'normal')}`

export const H2 = styled.div`${ts(textstyles.h2, 'black', 'normal')}`

export const H3 = styled.div`${ts(textstyles.h3, 'black', 'normal')}`

export const H4 = styled.div`${ts(textstyles.h4, 'black', 'normal')}`

export const H5 = styled.div`${ts(textstyles.h5, 'black', 'normal')}`

export const H6 = styled.div`${ts(textstyles.h6, 'black', 'normal')}`

export const Subtitle1 = styled.div`${ts(textstyles.subtitle1, 'black', 'normal')}`

export const Subtitle2 = styled.div`${ts(textstyles.subtitle2, 'black', 'normal')}`

export const Body1 = styled.div`${ts(textstyles.body1, 'black', 'normal')}`

export const Body2 = styled.div`${ts(textstyles.body2, 'black', 'normal')}`

export const Caption = styled.div`${ts(textstyles.caption, 'black', 'normal')}`

export const Overline = styled.div`${ts(textstyles.overline, 'black', 'normal')}`

export const ButtonText = styled.div`${ts(textstyles.button, 'black', 'normal')}`