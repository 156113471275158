import profile from './profile'
import identities from './identities'
import config from './config'
import driving from './driving'
import taxes from './taxes'
import gigs from './gigs'
import gigSearch from './gigSearch'
import cag from './cag'
import createGig from './createGig'
import creditBalance from './creditBalance'
import notifications from './notifications'
import trainingCertifications from './trainingCertifications'
import earningsStatement from './earningsStatement'
import mfa from './mfa'

function metaReduce(...reducers) {
  return (previous, current) =>
    reducers.reduce(
      (p, r) => r(p, current),
      previous
    )
}

const reducers = metaReduce(
  config, mfa, profile, identities, driving, taxes, 
  cag, gigs, gigSearch, createGig, notifications, creditBalance, trainingCertifications, 
  earningsStatement
)

export default function rootReducer(state, action) {
  switch(action.type) {
    case 'SIGN_OUT': {
      const cleaned = reducers({}, action)

      cleaned.config.badges = [ ...state.config.badges ]
      cleaned.config.appRootPath = state.config.appRootPath
      
      return cleaned
    }
    default: return reducers(state, action)
  }
}