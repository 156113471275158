import React, { FC, useState, useEffect, useCallback } from 'react'
import { Button, Container, Text, Image } from 'roadie-ui'
import { humanize } from 'util/format'
import { Time } from 'roadie-ui'
import { TrackingEvent, TrackingApiData } from "./types"
import { fetchTrackingDeliveryImages } from 'data/track'
import { Modal } from 'roadie-ui'
import Gallery from 'rui/widgets/Gallery'

type Props = {
  tracking: TrackingApiData
  code: string
}

const GalleryImage:FC<{src:string}> = props => {
  const { src } = props
  return (
    <Container.Base $backgroundColor="black" $maxHeight="70vh">
      <Image $maxHeight="70vh" src={src} />
    </Container.Base>
  )
}

const TrackingHistory:FC<Props> = props => {

  const { tracking, code } = props
  
  const [deliveryImages, setDeliveryImages] = useState<{ id:number, index:number | null, type: "delivery", url: string}[]>([])
  const [showingImages, setShowingImages] = useState(false)

  useEffect(() => {
    fetchTrackingDeliveryImages(code)
      .then(resp => setDeliveryImages(resp.images))
  }, [code])

  const toggleGallery = useCallback(() => setShowingImages(showing => !showing), [])
  const canViewDeliveryPhoto = useCallback((event: TrackingEvent) => {
    if (event.event_type !== 'delivery_confirmed') {
      return false
    }
    if (deliveryImages.length === 0) {
      return false
    }

    const eventTime = new Date(event.created_at).getTime()
    const now = Date.now()

    return now < eventTime + (14 * 24 * 60 * 60 * 1000) //14 days
  }, [deliveryImages.length])
  const events = tracking.events.reduce((events, event) => {
    if (['pickup_confirmed', 'delivery_details_added', 'delivery_confirmed', 'gig_cancelled'].includes(event.event_type)) {
      events.push(event)
    }
    return events
  }, [] as TrackingEvent[]).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())

  if (tracking.created_at) {
    events.push({
      event_type: 'gig_created',
      created_at: tracking.created_at,
      location: tracking.pickup_location,
    })
  }

  if (tracking.state === 'delivery_attempted') {
    events.unshift({
      event_type: 'delivery_attempted',
      created_at: tracking.updated_at,
      location: tracking.delivery_location
    })
  }

  return (
    <Container.Panel className="tracking-history" $padding="0" $paddingMedia={{mobile: '0'}}>
      <Container.Grid
        $gridTemplateColumns="1fr 1fr"
      >
        <Container.GridItem $padding="1.6rem 0 1.6rem 3rem">
          <Text.Overline>Status</Text.Overline>
        </Container.GridItem>
        <Container.GridItem $padding="1.6rem 3rem 1.6rem 0">
          <Text.Overline>Time</Text.Overline>
        </Container.GridItem>

        {events.map((event, index) => (
          <React.Fragment key={event.event_type}>
            <Container.GridItem
              className={`row-${index}-column-status`}
              $backgroundColor={index % 2 === 0 ? 'background' : 'clear'}
              $padding="2.4rem 0 2.4rem 3rem"
            >
              <Text.Subtitle2 $color="black">{humanize(event.event_type)}</Text.Subtitle2>
              { canViewDeliveryPhoto(event) ? 
                <Button.Tertiary $textAlign="left" onClick={toggleGallery}>View Delivery Photo</Button.Tertiary> : 
                null
              }
            </Container.GridItem>
            <Container.GridItem
              className={`row-${index}-column-time`}
              $backgroundColor={index % 2 === 0 ? 'background' : 'clear'}
              $padding="2.4rem 3rem 2.4rem 0"
            >
              <Text.Subtitle2>{Time.fromDate(new Date(event.created_at)).toString()}</Text.Subtitle2>
            </Container.GridItem>
          </React.Fragment>
        ))}
      </Container.Grid>
      {showingImages ? (
        <Modal.Raw onRequestClose={toggleGallery}>
          {deliveryImages.length > 1 ? (
            <Gallery onRequestClose={toggleGallery}>
              {deliveryImages.map(img => <GalleryImage key={img.url} src={img.url} /> )}
            </Gallery>
          ) : (
            <GalleryImage src={deliveryImages[0].url} />
          )}
        </Modal.Raw>
      ) : null}
    </Container.Panel>
  )
}

export default TrackingHistory