import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flash } from 'roadie-ui'

type Config = {
  flashStack: Flash.FlashMessage[]
  modalOpen: boolean
}

type FlashConnectorProps = Omit<Flash.FlashControllerProps, 'flash' | 'onClose'>

const FlashConnector:FC<FlashConnectorProps> = props => {
  const flash = useSelector(state => {
    // @ts-expect-error
    const config:Config = state.config
    const flash = config.flashStack.length ? config.flashStack[config.flashStack.length-1] : null
    return flash
  })

  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch({ type: 'POP_FLASH' })
  }, [dispatch])

  return <Flash.FlashController {...props} flash={flash} onClose={handleClose} />
}

export default FlashConnector