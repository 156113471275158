import 'declared_modules'
import 'polyfill'
import 'util/console'
import 'fbSDK'
import 'GTM'
import 'util/timeSetup'
import React from 'react'
import ReactDOM from 'react-dom'
import { renderToStaticMarkup } from "react-dom/server"
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { Provider } from 'react-redux'
import configureStore from 'store/configureStore'
// import * as serviceWorker from './serviceWorker'
import * as roadieWorker from './roadieWorker'
import watchWindowSize from 'windowSize'
import { join } from 'util/path'
import { HelmetProvider } from 'react-helmet-async'

import RoadieThemeProvider from 'RoadieThemeProvider'

import { api, session } from 'roadie-ui'

import { MapSystem } from 'roadie-ui'

import TrackApp from './TrackApp'

MapSystem.setup({ renderToStaticMarkup })

const initialState = {}

if (localStorage.getItem('access-token')) {
  const data = JSON.parse(localStorage.getItem('access-token')!)
  const identity = localStorage.getItem('default-identity') || undefined
  session.create(data, { identity })
}

export const store = configureStore(initialState)

export type StoreStateType = ReturnType<typeof store.getState>

export function absoluteURL(path:string) {
  const { appRootPath } = store.getState().config
  return join(appRootPath, path)
}

watchWindowSize(store)

if (process.env.REACT_APP_CAN_USE_CUSTOM_API) {
  const query = new URLSearchParams(window.location.search)
  const apiBase = query.get('api') || sessionStorage.getItem("api-base")
  if (apiBase) {
    sessionStorage.setItem("api-base", apiBase)
    api.setApiBaseUrl(apiBase)
  }
} else {
  sessionStorage.removeItem("api-base")
}

ReactDOM.render(
  <Provider store={store}>
    {/* @ts-expect-error */}
    <HelmetProvider>
      <RoadieThemeProvider>
        <TrackApp />
      </RoadieThemeProvider>
    </HelmetProvider>
  </Provider>, 
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()

if (process.env.REACT_APP_ROOT_MOUNT) {
  roadieWorker.unregister()
} else {
  roadieWorker.register(store)
}