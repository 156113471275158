import React from 'react'
import styled from 'styled-components/macro'
import { media } from 'styles/mixins'

const ThinOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ThinInner = styled.div`
  width: 100%;
  max-width: 48rem;
  ${media.mobile`
    max-width: none;
  `}
`

const Thin = props => (
  <ThinOuter>
    <ThinInner className={props.className}>
      {props.children}
    </ThinInner>
  </ThinOuter>
)

export default Thin