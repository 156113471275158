import React, { FC, useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { isTrackURL } from 'util/track'
import { Form, Container, Spacer, Text, FormSerialization } from 'roadie-ui'
import Thin from 'components/layout/Thin'

const TrackForm:FC<{themeName?:string,badCode?:string}> = props => {

  const { themeName, badCode } = props

  const [tracking, setTracking] = useState("")

  const handleData = useCallback((data:FormSerialization) => {
    setTracking(data.tracking.replace(/–/g, ''))
    return Promise.resolve()
  }, [])

  if (tracking) {
    return <Redirect push to={`/${isTrackURL() && themeName ? `${themeName}/` : ''}id/${tracking}`} />
  }

  return(
    <Thin>
      <Spacer $height="6.4rem" />
      <Form.Container onSubmit={handleData} maxColumns={1} validations={[
        ['tracking', Form.validations.required],
        ['tracking', Form.validations.exactLength(16)],
        ['tracking', badCode ? Form.validations.not(badCode) : Form.validations.none, 'Please choose a different tracking number'],
      ]}>
        <Container.Panel>
          <Text.H4 $textAlign="center">Delivery Tracker</Text.H4>
          <Spacer $height=".8rem" />
          <Text.Subtitle1 $textAlign="center">Enter your Tracking Number below to see minute by minute tracking!</Text.Subtitle1>
          <Spacer $height=".8rem" />

          <Form.Input
            type="tracking"
            name="tracking"
            label="•••• – •••• – •••• – ••••"
            defaultValue={badCode}
          />

          <Spacer $height="3.2rem" />

          <Form.Submit>Track Now</Form.Submit>
          
        </Container.Panel>

        <Spacer $height="1.6rem" />
        <Text.Body2 $textAlign="center">Don’t have a tracking number?<br />You should be receiving an SMS or Email soon.</Text.Body2>
      </Form.Container>
    </Thin>
  )
}

export default TrackForm