import { css } from 'styled-components/macro'

export default function link() {
  return css`
    transition: all 150ms ease;
    text-decoration: none;
    outline: none;
    color: ${props => props.theme.colors.primaryBase};
    font-weight: 700;
    display: inline-block;

    &:hover {
      color: ${props => props.theme.colors.primaryDarker};
    }
  `
}