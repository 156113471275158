import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Container, Effect, Spacer, Text, Icon, Button, Faq, parsers } from 'roadie-ui'
import { Theme } from './types'

const FaqContent = styled.span`    
  a {
    color: ${parsers.color('primary')};
  }
  a:hover {
    color: ${parsers.color('primary.lighter')};
  }
`

const FooterHeader:FC<{children:ReactNode}> = props => (
  <Container.Base>
    <Text.H5 $color="lookup:footerfg">{props.children}</Text.H5>
    <Spacer $height=".8rem" />
    <Effect.Opacity $opacity=".5">
      <Container.Base $backgroundColor="lookup:footerfg" $height="2px" />
    </Effect.Opacity>
  </Container.Base>
)

const TrackingFooter:FC<{
  faqTitle: NonNullable<Theme["faqTitle"]>
  faq: Theme["faq"]
  helpTitle: NonNullable<Theme["helpTitle"]>
  helpNumber: NonNullable<Theme["helpNumber"]>
}> = props => {
  const { faqTitle, faq, helpTitle, helpNumber } = props
  
  return (
    <Container.Base>
      <FooterHeader>{faqTitle}</FooterHeader>
        <Container.Grid
          $gridTemplateColumns="1fr 1fr"
          $gridTemplateColumnsMedia={{mobile: '1fr'}}
          $gridAutoRows="auto"
          $color="lookup:footerfg"
        >
          {faq.map(({ q, a }) => (
            <Faq titleTextColor="lookup:footerfg" bodyTextColor="lookup:footerfg" faintNotch key={`faq-${q}`} title={q}><FaqContent dangerouslySetInnerHTML={{__html: a}} /></Faq>
          ))}
        </Container.Grid>
      <Spacer $height="3.2rem" />
      <FooterHeader>{helpTitle}</FooterHeader>
      <Spacer $height="1.2rem" />
      <Button.A.Tertiary
        $display="inline-block"
        $color="primary"
        $colorHover="primary.lighter"
        href={helpNumber.value}
      >
        <Container.Grid
          $gridTemplateColumns="3rem 1fr"
          $gridGap="1rem"
          $alignItems="center"
        >
          <Icon.Phone />
          {helpNumber.label}
        </Container.Grid>
      </Button.A.Tertiary>
    </Container.Base>
  )
}

export default TrackingFooter