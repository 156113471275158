import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Body2 } from 'components/layout/text'
import { useDispatch } from 'react-redux'

const ToastContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  bottom: 40px;
  z-index: 999999;
  width: 100%;
  display: flex;
  justify-content: center;
`

const ToastContent = styled.div`
  transition: transform 1s ease-in;
  padding: 15px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  max-width: 80%;
  box-shadow: ${props => props.theme.colors.black} 0 0 3px;
`

const ToastMessage = ({ toast: { message, duration = 10000 } }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch({ type: 'SET_TOAST', toast: {} })
      }, duration)
    }
  }, [message, duration, dispatch])

  return (
      <>
      {
        message ? (
          <ToastContainer>
            <ToastContent>
              <Body2>{message}</Body2>
            </ToastContent>
          </ToastContainer>
        ) : null
      }
      </>
  )
}

export default ToastMessage
