import React, { FC, ReactNode, useEffect, useCallback, useState, MouseEvent, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import Avatar from 'rui/widgets/Avatar'
import FlashConnector from './FlashConnector'
import ToastMessage from 'components/layout/ToastMessage'
import { api, Hovercard, Box, Button, Text, Span, Container, Icon, Spacer, Messages } from 'roadie-ui'

import { store } from 'index'
import IdentitiesAvailable from 'rui/widgets/Identity/Available'
import { AccessibleIdentity } from 'data/identities'

type StoreStateType = ReturnType<typeof store.getState>

const RoadieLogoLink = styled(Link)`
  align-items: center;
  display: inline-flex;
`

const FlashStickyWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100000000;
`

const HeaderLink:FC<{children:ReactNode, isActive?:boolean, to:string, id?:string}> = props => {
  return (
    <Button.Link.Primary
      id={props.id}
      to={props.to}
      $padding="2.2rem 3.5rem"
      $paddingMedia={{mobile: '2.2rem 2rem'}}
      $display="inline-block"
      $backgroundColor={props.isActive ? 'black@.4' : 'clear'}
      $backgroundColorHover={props.isActive ? 'black@.4' : 'clear'}
      $borderColor="clear"
      $borderRadius="0"
    >
      {props.children}
    </Button.Link.Primary>
  )
}

const ActiveAppLink:FC<{children:ReactNode, to:string, id?:string}> = props => {
  const location = useLocation()
  const [active, setActive] = useState(false)
  useEffect(() => {
    setActive(location.pathname.startsWith(props.to))
  }, [location.pathname, props.to])
  return (
    <HeaderLink id={props.id} isActive={active} to={props.to}>{props.children}</HeaderLink>
  )
}

const HeaderAvatar = styled(Avatar).attrs({ size: 32 })<{url:string}>`
  margin-right: 1rem;
`

const NavLinks:FC<{children:ReactNode}> = props => (
  <Container.Grid $gridTemplateColumns="auto auto">
    {props.children}
  </Container.Grid>
)

const SidebarMenuIcon:FC<{sidebar:any, onClick:(event: MouseEvent) => void}> = props => {
  if (props.sidebar) {
    return (
      <Container.Base
        $displayMedia={{desktop: 'none'}}
        $height="3rem"
      >
        <Icon.Menu
          $color="white"
          $width="3rem"
          $cursor="pointer"
          onClick={props.onClick}
        />
      </Container.Base>
    )
  } else {
    return null
  }
}

const BGWrapper = styled.div`
  > * {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`

const CustomApi = () => {
  const apiURL = sessionStorage.getItem("api-base")
  const resetApiBaseUrl = useCallback(() => {
    api.resetApiBaseUrl()
  }, [])
  if (apiURL) {
    return (
      <Box.Model
        $padding=".5rem 0 0"
        $textAlign="center"
        $backgroundColor="alert"
        $borderBottomWidth="3px"
        $borderBottomStyle="dashed"
        $borderBottomColor="primary.darker"
        $color="black"
        $height="5rem"
      >
        <Container.Grid
          $gridTemplateColumns="auto auto"
          $alignItems="center"
          $justifyItems="center"
        >
          <span><b>CUSTOM API:</b> {apiURL} </span>
          <Button.Primary $hue="alert" onClick={resetApiBaseUrl}>Unset</Button.Primary>
        </Container.Grid>
      </Box.Model>
    )
  }
  return null
}

const HeaderLogo = () => {
  const mediaSize = useSelector((state:StoreStateType) => state.config.mediaSize)

  const svg = mediaSize === 'mobile' ? 'Logo' : 'RoadieUpsCompany'

  return (
    <Icon svg={svg} $color="white" $height="4rem" />
  )
}

const AppLinks:FC<{appRootPath:string}> = props => {
  if (!props.appRootPath || props.appRootPath === '/') {
    return null
  }
  return (
    <NavLinks>
      <ActiveAppLink id="send-app-link" to="/send">Send</ActiveAppLink>
      <ActiveAppLink id="drive-app-link" to="/drive">Drive</ActiveAppLink>
    </NavLinks>
  )
}

const IdentityChooser:FC<{profile:any}> = props => {
  const identities = useSelector<any, AccessibleIdentity[]>(state => state.identities.available)
  const hasMultipleIdentities = identities && identities.length > 1
  const name = props.profile.business_name ? props.profile.business_name : `${props.profile.first_name} ${(props.profile.last_name || '').substr(0, 1)}.`
  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => {
    if (hasMultipleIdentities) {
      setOpen(was => !was)
    }
  }, [hasMultipleIdentities])
  const containerRef = useRef<HTMLDivElement>(null)
  return (
    <Container.Grid
      $alignItems="center"
      $gridTemplateColumns="3rem 1fr"
      $gridGap="1rem"
      $displayMedia={{mobile:'none',tablet:'none'}}
      ref={containerRef}
    >
      <HeaderAvatar id="header-avatar" url={props.profile.avatar_url} />
      <Button.Tertiary
        $color="white"
        $colorHover="white"
        onClick={handleOpen}
      >
        {name} {hasMultipleIdentities ? (
          <Icon svg={open ? 'ChevronDown' : 'ChevronRight'} $width="1rem" />
        ) : null}
      </Button.Tertiary>
      <Hovercard
        onClickAway={handleOpen}
        dockBelow={open ? containerRef.current : null}
        color="white"
        width="auto"
        offsetY={-10}
      >
        <IdentitiesAvailable />
      </Hovercard>
    </Container.Grid>
  )
}

const UserLinks:FC<{profile:any, appRootPath:string}> = props => (
  <NavLinks>
    <IdentityChooser profile={props.profile} />
    <HeaderLink id="header-sign-out" to="/sign-out">Sign Out</HeaderLink>
  </NavLinks>
)

type LayoutProps = {
  children: ReactNode
  sidebar?: ReactNode
  background?: ReactNode 
  needs?: string
  header?: false | ReactNode
  footer?: boolean
  fullscreen?: boolean
}

const MainLayout:FC<LayoutProps> = props => {
  const { children, sidebar, header=true, footer=true, background=<Container.Base $backgroundColor="white" />, fullscreen=false } = props
  const { profile, appRootPath, toast } = useSelector((state:StoreStateType) => {
    const { profile, config } = state
    return {
      profile: profile,
      appRootPath: config.appRootPath,
      toast: config.toast
    }
  })
  const dispatch = useDispatch()
  const handleOpenSidebar = useCallback((event:MouseEvent) => {
    event.preventDefault()
    dispatch({ type: 'SET_SIDEBAR_OPEN', open: true })
  }, [dispatch])
  let maxWidth = '102.5rem'
  let pagePadding = '5rem'

  if (fullscreen) {
    maxWidth = '100%'
    pagePadding = '0'
  }
  return (
    <div>
        <BGWrapper>
          {background}
        </BGWrapper>
        <Container.Grid
          $minHeight="100vh"
          $gridTemplateRows="auto 1fr auto"
        >
          <div>
            <CustomApi />
            {header === true ? (
              <Container.Grid
                $backgroundColor="lookup:headerbg"
                $alignItems="center"
                $justifyContent="stretch"
                $justifyItems="center"
              >
                <Container.Grid
                  $height="6.4rem"
                  $width="100%"
                  $maxWidth={maxWidth}
                  $justifyContent="space-between"
                  $gridTemplateColumns="auto auto"
                  $alignItems="center"
                  $padding="0 1.5rem"
                  $paddingMedia={{mobile: '0'}}
                >
                  <Container.Grid
                    $alignItems="center"
                    $padding="0 3.5rem"
                    $paddingMedia={{mobile: '0 2rem'}}
                    $gridTemplateColumnsMedia={{desktop: 'auto'}}
                    $gridGapMedia={{desktop: '0'}}
                    $gridTemplateColumns="3rem auto"
                    $gridGap="1.5rem"
                  >
                    <SidebarMenuIcon onClick={handleOpenSidebar} sidebar={sidebar} />
                    <RoadieLogoLink id="logo" to={appRootPath || '/'}>
                      <HeaderLogo />
                    </RoadieLogoLink>
                  </Container.Grid>
                  <Container.Grid $gridTemplateColumns="auto auto auto">
                    {profile ? <UserLinks profile={profile} appRootPath={appRootPath} /> : <AppLinks appRootPath={appRootPath} />}
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            ) : header ? header : null}
          </div>
        <div>
            <FlashStickyWrapper>
              <FlashConnector />
            </FlashStickyWrapper>

            <ToastMessage toast={toast} />

            <Container.Grid
              $padding={`${pagePadding} ${pagePadding} 0`}
              $paddingMedia={{mobile: '0'}}
              $width="100%"
              $maxWidth={maxWidth}
              $minHeight="100%"
              $gridTemplateColumns="100%"
              $justifyItems="center"
            >
              {children}
            </Container.Grid>
          </div>
          <div>
            {footer ? (
              <div>
                <Spacer $height="4rem" />
                <Container.Position $position="relative">
                  <Container.Base>
                    <Container.Base $textAlign="center">
                      <Span.Body2><a href="https://support.roadie.com" target="_blank" rel="noopener nofollower noreferrer">Help</a></Span.Body2>
                      <Span.Body2 $userSelect="none" $color="grey.light">{' // '}</Span.Body2>
                      <Span.Body2><a href="https://www.roadie.com/privacy" target="_blank" rel="noopener nofollower noreferrer">Privacy</a></Span.Body2>
                      <Span.Body2 $userSelect="none" $color="grey.light">{' // '}</Span.Body2>
                      <Span.Body2><a href="https://www.roadie.com/terms" target="_blank" rel="noopener nofollower noreferrer">Terms</a></Span.Body2>
                    </Container.Base>
                    <Spacer $height=".4rem" />
                    <Text.Body2 $textAlign="center">©{new Date().getFullYear()} Roadie, Inc. All rights reserved</Text.Body2>
                  </Container.Base>
                </Container.Position>
                <Spacer $height="4rem" />
              </div>
            ) : null}
          </div>
        </Container.Grid>
      { appRootPath ? <Messages app={appRootPath.substring(0)} /> : null }
    </div>
  )
}

export default MainLayout