import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components/macro'
import { isWebviewSelector } from 'selectors'
import {sendNativeMessage } from 'util/native'
import link from 'styles/mixins/link'
import { Link } from 'react-router-dom'
import isObject from 'lodash/isObject'

const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

const DivAnchor = styled.div`
  ${link()}
`

const Anchor = styled.a`
  ${props => props.customStyles && css`
    width: 20rem;
    background: ${props => props.disabled ? props.theme.colors.greyLight : props.theme.colors.primaryBase};
    color: ${props.theme.colors.white};
    padding: 1.5rem;
    border-radius: 5px;
    text-transform: uppercase;
    border: 1px solid ${props => props.disabled ? 'transparent' : props.theme.colors.primaryBase};
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .125rem;
    &:hover {
      background: ${props => props.theme.colors.primaryDarker};
      border-color: ${props => props.theme.colors.primaryDarker};
      color: ${props.theme.colors.white};
    }
  `}

  ${props => props.disabled && css`
      pointer-events: none;
      cursor: default;
      color: ${props => props.theme.colors.greyDark};
    `}
`;

function buildMailTo(email) {
  return `mailto:${email.to}?subject=${encodeURIComponent(email.subject)}&body=${encodeURIComponent(email.body)}`
}

export const ExternalLink = connect(isWebviewSelector)(props => {
  const { isWebview, dispatch, email, ...otherProps } = props
  const isEmail = email || (props.href && props.href.startsWith('mailto'))
  if (iOS && isWebview) {
    const handleClick = event => {
      if (email) {
        sendNativeMessage({
          type: 'SEND_EMAIL',
          email: {
            to: email.to,
            subject: email.subject,
            body: email.body, 
          }
        })
      } else {
        sendNativeMessage({
          type: 'LINK_CLICK',
          href: props.href,
        })
      }
    }
    return <DivAnchor {...otherProps} onClick={handleClick} />
  } else {
    if (email) {
      otherProps.href = buildMailTo(email)
    }
    return <Anchor target={isWebview && isEmail ? '' : '_blank'} rel="noopener noreferrer" {...otherProps} />
  }
})

const generateFullPath = to => {
  const last = isObject(to) ? to.pathname : to
  const href = window.location.href.split('/').slice(0, -1)
  return [...href, last].filter(part => part !== 'webview').join('/')
}

export const AdaptiveExternalLink = connect(isWebviewSelector)(
  props => props.isWebview 
    ? <ExternalLink {...props} href={generateFullPath(props.to)} /> 
    : <Link {...props} />
)