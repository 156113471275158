import React, { Component } from 'react'
import styled, { css } from 'styled-components/macro'
import palette from 'styles/palette'
import { ReactComponent as RatingStar } from 'svg/rating-star.svg'
import { ReactComponent as Expense } from 'svg/expense.svg'
import { ReactComponent as StopPickup } from 'svg/stop-pickup.svg'
import { ReactComponent as StopDelivery } from 'svg/stop-delivery.svg'
import { ReactComponent as StopReturn } from 'svg/stop-return.svg'
import { ReactComponent as ShipmentStar } from 'svg/shipment-star.svg'
import { ReactComponent as ShipmentPickip } from 'svg/pickup.svg'
import { ReactComponent as ShipmentDropoff } from 'svg/dropoff.svg'
import { ReactComponent as ShipmentInfo } from 'svg/shipment-info.svg'
import { ReactComponent as ShipmentPayment } from 'svg/shipment-payment.svg'
import { ReactComponent as ShipmentPlus } from 'svg/shipment-plus.svg'
import { ReactComponent as TruckDriving } from 'svg/truck-driving.svg'
import { ReactComponent as Menu } from 'svg/menu.svg'
import { ReactComponent as Close } from 'svg/close.svg'
import { ReactComponent as Check } from 'svg/check.svg'
import { ReactComponent as Lock } from 'svg/lock.svg'
import { ReactComponent as Ban } from 'svg/ban.svg'
import { ReactComponent as Shield } from 'svg/shield.svg'
import { ReactComponent as CompassArrow } from 'svg/compass-arrow.svg'
import { ReactComponent as ArrowLeft } from 'svg/arrow-left.svg'
import { ReactComponent as ArrowRight } from 'svg/arrow-right.svg'
import { ReactComponent as ArrowDownActive } from 'svg/arrow-down-active.svg'
import { ReactComponent as ArrowDownDisabled } from 'svg/arrow-down-disabled.svg'
import { ReactComponent as ChevronRight } from 'svg/chevron-right.svg'
import { ReactComponent as LogoCircle } from 'svg/logo-circle.svg'
import { ReactComponent as LogoText } from 'svg/logo-text.svg'
import { ReactComponent as Box } from 'svg/box.svg'
import { ReactComponent as NewsPaper } from 'svg/news-paper.svg'
import { ReactComponent as LoadingBar } from 'svg/loading-bar.svg'
import { ReactComponent as Phone } from 'svg/phone.svg'
import { ReactComponent as MoneyPin } from 'svg/cash-pin-map.svg'
import { ReactComponent as MapIcon } from 'svg/map.svg'
import { ReactComponent as Caution } from 'svg/caution.svg'
import { ReactComponent as CagTruck } from 'svg/cag-truck.svg'
import { ReactComponent as CagShield } from 'svg/cag-shield.svg'
import { ReactComponent as CagPlusOne } from 'svg/cag-plus-one.svg'
import { ReactComponent as CagCoins } from 'svg/cag-coins.svg'
import { ReactComponent as Bank } from 'svg/bank.svg'
import { ReactComponent as PaymentCreditCard } from 'svg/payment-cc.svg'
import { ReactComponent as PaymentPaypal } from 'svg/payment-paypal.svg'
import { ReactComponent as PaymentInvoice } from 'svg/payment-invoice.svg'
import { ReactComponent as PortalDrive } from 'svg/drive-portal.svg'
import { ReactComponent as PortalSend } from 'svg/send-portal.svg'
import { ReactComponent as PortalGo } from 'svg/portal-go.svg'
import { ReactComponent as QuestionCircle } from 'svg/question-circle.svg'
import { ReactComponent as VideoCircle } from 'svg/video-circle.svg'
import { ReactComponent as VideoPlay } from 'svg/video-play.svg'
import { ReactComponent as Camera } from 'svg/icon-camera.svg'
import { ReactComponent as Link } from 'svg/icon-hyperlink.svg'
import { ReactComponent as BoxDimensions } from 'svg/box-dimensions.svg'
import { ReactComponent as Install } from 'svg/install.svg'
import { ReactComponent as Search } from 'svg/search.svg'
import { ReactComponent as Calendar } from 'svg/calendar.svg'
import { ReactComponent as Logo } from 'svg/logo.svg'
import { ReactComponent as Envelope } from 'svg/envelope.svg'
import { ReactComponent as Sms } from 'svg/sms.svg'
import { ReactComponent as CheckCircle } from 'svg/check-circle.svg'
import { ReactComponent as BulkCheckGrey } from 'svg/bulk-check-grey.svg'
import { ReactComponent as BulkCheckGreen } from 'svg/bulk-check-green.svg'
import { ReactComponent as BulkExRed } from 'svg/bulk-ex-red.svg'
import { ReactComponent as ByDriverGigPin } from 'svg/gig-by-driver-pin.svg'
import { ReactComponent as ByDriverGigArrow } from 'svg/gig-by-driver-arrow.svg'
import { ReactComponent as NewPickup } from 'svg/new-pickup.svg'
import { ReactComponent as NewDelivery } from 'svg/new-delivery.svg'
import { ReactComponent as ImportSVG } from 'svg/import-new.svg'
import { ReactComponent as ImportErrorSVG } from 'svg/import-error.svg'
import { ReactComponent as ImportSuccessSVG } from 'svg/import-success.svg'
import { ReactComponent as ImportProcessingSVG } from 'svg/import-processing-new.svg'
import { ReactComponent as PersonalNew } from 'svg/personal-new.svg'
import { ReactComponent as BusinessNew } from 'svg/business-new.svg'
import { ReactComponent as PaymentCreditCardHelper } from 'svg/payment-cc-helper.svg'
import { ReactComponent as AlertRed } from 'svg/alert.svg'
import { ReactComponent as Cross } from 'svg/cross.svg'
import { ReactComponent as Edit } from 'svg/edit.svg'
import { ReactComponent as Plus } from 'svg/plus.svg'
import { ReactComponent as SortAsc } from 'svg/sort-asc.svg'
import { ReactComponent as SortDesc } from 'svg/sort-desc.svg'
import { ReactComponent as Sort } from 'svg/sort.svg'
import { ReactComponent as Settings } from 'svg/settings.svg'
import { ReactComponent as VehicleTypeCar } from 'svg/foundation-icons-vehicles-car-car-md.svg'
import { ReactComponent as VehicleTypePickup } from 'svg/foundation-icons-vehicles-pickup-pickup-md.svg'
import { ReactComponent as VehicleTypeSuv } from 'svg/foundation-icons-vehicles-suv-suv-md.svg'
import { ReactComponent as VehicleTypeTruck } from 'svg/foundation-icons-vehicles-truck-truck-md.svg'
import { ReactComponent as VehicleTypeVan } from 'svg/foundation-icons-vehicles-van-van-md.svg'
import { ReactComponent as Alert } from 'svg/foundation-icons-alert-circle-md.svg'
import { ReactComponent as Replay } from 'svg/ic-replay.svg'
import { ReactComponent as SuccessOutline } from 'svg/success-outline.svg'
import { ReactComponent as AttentionOutline } from 'svg/attention-outline.svg'
import { ReactComponent as WarningOutline } from 'svg/warning-outline.svg'
import { ReactComponent as ExternalLinkActive } from 'svg/external-link-active.svg'
import { ReactComponent as ExternalLinkDisabled } from 'svg/external-link-disabled.svg'
import { ReactComponent as VideoPlayCircled } from 'svg/video-play-circled.svg'

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  svg .icon-stroke {
    stroke: currentColor;
  }
  svg .icon-fill {
    fill: currentColor;
  }

  ${props => props.palette && css`
    svg .icon-stroke {
      stroke: ${palette('base', 'grey')};
    }
    svg .icon-fill {
      fill: ${palette('base', 'grey')};
    }
  `}
`

export default class Icon extends Component {

  getSvg(symbol) {
    switch(symbol) {
      case 'star': return RatingStar
      case 'expense': return Expense
      case 'stop-pickup': return StopPickup
      case 'stop-delivery': return StopDelivery
      case 'stop-return': return StopReturn
      case 'shipment-star': return ShipmentStar
      case 'shipment-pickup': return ShipmentPickip
      case 'shipment-dropoff': return ShipmentDropoff
      case 'shipment-info': return ShipmentInfo
      case 'shipment-payment': return ShipmentPayment
      case 'shipment-plus': return ShipmentPlus
      case 'truck-driving': return TruckDriving
      case 'menu': return Menu
      case 'close':  return Close
      case 'check': return Check
      case 'lock': return Lock
      case 'ban': return Ban
      case 'shield': return Shield
      case 'compass-arrow': return CompassArrow
      case 'arrow-left': return ArrowLeft
      case 'arrow-right': return ArrowRight
      case 'arrow-down-active': return ArrowDownActive
      case 'arrow-down-disabled': return ArrowDownDisabled
      case 'chevron-right': return ChevronRight
      case 'external-link-active': return ExternalLinkActive
      case 'external-link-disabled': return ExternalLinkDisabled
      case 'logo': return Logo
      case 'logo-circle': return LogoCircle
      case 'logo-text': return LogoText
      case 'box': return Box
      case 'news-paper': return NewsPaper
      case 'loading-bar': return LoadingBar
      case 'phone': return Phone
      case 'map': return MapIcon
      case 'money-pin': return MoneyPin
      case 'caution': return Caution
      case 'cag-truck': return CagTruck
      case 'cag-shield': return CagShield
      case 'cag-plus-one': return CagPlusOne
      case 'cag-coins': return CagCoins
      case 'payment-bank': return Bank
      case 'payment-cc': return PaymentCreditCard
      case 'payment-paypal': return PaymentPaypal
      case 'payment-invoice': return PaymentInvoice
      case 'portal-drive': return PortalDrive
      case 'portal-send': return PortalSend
      case 'portal-go': return PortalGo
      case 'question-circle': return QuestionCircle
      case 'video-circle': return VideoCircle
      case 'video-play': return VideoPlay
      case 'camera': return Camera
      case 'link': return Link
      case 'box-dimensions': return BoxDimensions
      case 'install': return Install
      case 'search': return Search
      case 'calendar': return Calendar
      case 'envelope': return Envelope
      case 'sms': return Sms
      case 'success-outline': return SuccessOutline
      case 'attention-outline': return AttentionOutline
      case 'warning-outline': return WarningOutline
      case 'check-circle': return CheckCircle
      case 'bulk-check-grey': return BulkCheckGrey
      case 'bulk-check-green': return BulkCheckGreen
      case 'bulk-ex-red': return BulkExRed
      case 'gig-by-driver-pin': return ByDriverGigPin
      case 'gig-by-driver-arrow': return ByDriverGigArrow
      case 'business': return BusinessNew
      case 'person': return PersonalNew
      case 'new-delivery': return NewDelivery
      case 'new-pickup': return NewPickup
      case 'sort-asc': return SortAsc
      case 'sort-desc': return SortDesc
      case 'sort': return Sort
      case 'settings': return Settings
      case 'vehicle-van': return VehicleTypeVan
      case 'vehicle-truck': return VehicleTypeTruck
      case 'vehicle-pickup': return VehicleTypePickup
      case 'vehicle-car': return VehicleTypeCar
      case 'vehicle-suv': return VehicleTypeSuv
      case 'alert-red': return AlertRed
      case 'import': return ImportSVG
      case 'import-processing': return ImportProcessingSVG
      case 'import-error': return ImportErrorSVG
      case 'import-success': return ImportSuccessSVG
      case 'payment-cc-helper': return PaymentCreditCardHelper
      case 'alert': return Alert
      case 'cross': return Cross
      case 'edit': return Edit
      case 'plus': return Plus
      case 'replay': return Replay
      case 'video-play-circled': return VideoPlayCircled
      default: return RatingStar
    }
  }

  render() {
    const { symbol, modalOpen, palette, shade, className, ...props } = this.props
    const SvgIcon = this.getSvg(this.props.symbol)
    
    return (
      <IconWrapper palette={palette} shade={shade} className={className}>
        <SvgIcon {...props} />
      </IconWrapper>
    )
  }
}