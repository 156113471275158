import { darken, lighten } from 'polished'
import { capitalize } from 'util/format'

export default function palette(defaultShade='base', defaultGroup='primary') {
  return props => {
    const shade = capitalize(props.shade || defaultShade)
    const group = props.palette || defaultGroup
    const color = props.theme.colors[`${group}${shade}`]
    if (color) {
      return color
    } else {
      const base = props.theme.colors[`${group}Base`] || props.theme.colors[group] || group
      switch(shade) {
        case 'Light': return lighten(0.585, base)
        case 'Lighter': return lighten(0.385, base)
        case 'Base': return base
        case 'Dark': return darken(0.11, base)
        case 'Darker': return darken(0.256, base)
        default: return base
      }
    }
  }
}