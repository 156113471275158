const TRACK_URL = process.env.REACT_APP_ROADIE_TRACK_URL

export function isTrackURL() {
  return TRACK_URL === `${window.location.protocol}//${window.location.host}`
}

export function redirectToTrackUrl() {
  if (TRACK_URL === 'https://react-sandbox.roadie.com') {
    return
  }
  window.location.href = `${TRACK_URL}${window.location.pathname}`
}