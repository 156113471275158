import React, { FC } from 'react'
import TrackForm from 'rui/track/Form'
import PoweredByRoadie from 'components/misc/PoweredByRoadieBadge'
import { Text, Span, Container, Spacer } from 'roadie-ui'

const FormPage:FC<{themeName?:string}> = props => {
  const { themeName } = props
  return (
    <>
      <TrackForm themeName={themeName} />
      
      <Container.GridItem 
        $alignSelf="end"
        $textAlign="center"
      >
        {themeName && themeName !== 'roadie' ? <>
          <Spacer $height="4rem" />
          <PoweredByRoadie />
          <Spacer $height="4rem" />
        </> : (
          // TODO: dry this up instead of copying footer from main layout
          // GOAL: tell root layout component to use default layout footer if "roadie" and specify custom footer if themed
          <div>
            <Spacer $height="4rem" />
            <Container.Position $position="relative">
              <Container.Base>
                <Container.Base $textAlign="center">
                  <Span.Body2><a href="https://support.roadie.com" target="_blank" rel="noopener nofollower noreferrer">Help</a></Span.Body2>
                  <Span.Body2 $userSelect="none" $color="grey.light">{' // '}</Span.Body2>
                  <Span.Body2><a href="https://www.roadie.com/privacy" target="_blank" rel="noopener nofollower noreferrer">Privacy</a></Span.Body2>
                  <Span.Body2 $userSelect="none" $color="grey.light">{' // '}</Span.Body2>
                  <Span.Body2><a href="https://www.roadie.com/terms" target="_blank" rel="noopener nofollower noreferrer">Terms</a></Span.Body2>
                </Container.Base>
                <Spacer $height=".4rem" />
                <Text.Body2 $textAlign="center">©{new Date().getFullYear()} Roadie, Inc. All rights reserved</Text.Body2>
              </Container.Base>
            </Container.Position>
            <Spacer $height="4rem" />
          </div>
        )}
      </Container.GridItem>
    </>
  )
}

export default FormPage