import React, { useState, Children, useEffect, useCallback } from 'react'
import { Button, Container, Icon } from 'roadie-ui'
import LightboxClose from './LightboxClose'

const GalleryButton:React.FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>
  position: 'left' | 'right'
}> = props => {
  const isLeftAligned = props.position === 'left'
  const wrapperProps = isLeftAligned ? {'$left': '0'} : {"$right": '0'}
  const borderRadius = isLeftAligned ? '0 5px 5px 0' : '5px 0 0 5px'
  const icon = isLeftAligned ? 'ChevronLeft' : 'ChevronRight'

  return (
    <Container.Position $position="absolute" $top="calc(50% - 1rem)" {...wrapperProps}>
      <Button.Primary onClick={props.onClick} 
        type="button"
        $padding="1rem .5rem"
        $borderStyle="none"
        $borderRadius={borderRadius}
        $backgroundColor="black@.75"
        $backgroundColorHover="black@.75"
        $color="white" 
        $colorHover="white">
        <Container.Grid $alignItems="center">
          <Icon svg={icon} $height="2rem" />
        </Container.Grid>
      </Button.Primary>
    </Container.Position>
  )
}

const Gallery:React.FC<React.PropsWithChildren<{
  startIndex?: number
  onRequestClose?: () => void
}>> = props => {
  const [totalSlides, setTotalSlides] = useState(Children.count(props.children))
  const [currentIndex, setIndex] = useState(Math.max(totalSlides - 1, props.startIndex ?? 0) || 0)
  const [currentSlide, setCurrentSlide] = useState(Children.toArray(props.children)[currentIndex])

  let handleNext = useCallback(() => {
    let nextIndex = currentIndex + 1
    if (nextIndex >= totalSlides) {
      nextIndex = 0
    }
    setIndex(nextIndex)
  }, [totalSlides, currentIndex])

  let handlePrev = useCallback(() => {
    let nextIndex = currentIndex - 1
    if (nextIndex < 0) {
      nextIndex = totalSlides - 1
    }
    setIndex(nextIndex)
  }, [totalSlides, currentIndex])

  useEffect(() => {
    let total = Children.count(props.children)
    setTotalSlides(total)
    setIndex(Math.max(total - 1, props.startIndex ?? 0) || 0)
  }, [props.children, props.startIndex])

  useEffect(() => {
    setCurrentSlide(Children.toArray(props.children)[currentIndex])
  }, [currentIndex, props.children])

  return (
    <Container.Position $position="relative">
      {currentSlide}
      <GalleryButton position="left" onClick={handlePrev} />
      <GalleryButton position="right" onClick={handleNext} />
      {props.onRequestClose ? <LightboxClose onClick={props.onRequestClose} /> : null}
    </Container.Position>
  )
}

export default Gallery