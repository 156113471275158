import { FC } from 'react'
import { AcceptableColorValue, Container, parsers, Spacer, Text } from 'roadie-ui'
import { useTheme } from 'styled-components'
import { Time } from 'roadie-ui'

type ProgressState = {
  wasPickedUp: boolean
  pickedUpAt?: Time
  
  hasStartedRoute: boolean
  startedRouteAt?: Time
  
  isDelivered: boolean
  deliveredAt?: Time
  
  hasFailed: boolean
  failedReason?: string
  
  deliveryDeadline: Time
  
  pickupLocation: string
  deliveryLocation: string
}

interface PickedUp extends ProgressState {
  wasPickedUp: true
  pickedUpAt: Time
}

interface OnTheWay extends PickedUp {
  hasStartedRoute: true
  startedRouteAt: Time
}

interface Delivered extends OnTheWay {
  isDelivered: true
  deliveredAt: Time
}

interface Failed extends ProgressState {
  hasFailed: true
  failedReason: string
}

type Progress = Failed | Delivered | OnTheWay | PickedUp | ProgressState

function wasPickedUp(props:Progress):props is PickedUp {
  return props.wasPickedUp
}

function hasStartedRoute(props:Progress):props is OnTheWay {
  return props.hasStartedRoute
}

function isDelivered(props:Progress):props is Delivered {
  return props.isDelivered
}

function hasFailed(props:Progress):props is Failed {
  return props.hasFailed
}

const ProgressDivider:FC<{
  fillLeft:AcceptableColorValue
  fillCenter:AcceptableColorValue
  fillRight:AcceptableColorValue
}> = props => {
  const theme = useTheme()
  const left = parsers.color(props.fillLeft)({ theme })
  const center = parsers.color(props.fillCenter)({ theme })
  const right = parsers.color(props.fillRight)({ theme })
  return (
    <svg viewBox="0 0 24 56" height="100%">
      <polygon fill={left} points="0 0 16 28 0 56"></polygon>
      <path fill={center} d="M0 0l16 28-16 28h8c9.33-16 14.33-24.67 15-26 1-2 1-2 0-4-.67-1.34-5.67-10-15-26Z"></path>
      <path fill={right} d="M24 56H8l14.93-28L8 0h16Z"></path>
    </svg>
  )
}

const TrackingProgressBar:FC<Progress> = props => {

  if (hasFailed(props)) {
    return (
      <Container.Base id="progress-bar" $width="100%">
        <Container.Base
          $borderRadius="3rem"
          $backgroundColor="error"
          $borderColor="white"
          $borderWidth="2px"
          $borderStyle="solid"
          $width="100%"
          $height="2.4rem"
        />
        <Text.Subtitle2 $textAlign="center" $color="black">{props.failedReason}</Text.Subtitle2>
      </Container.Base>
    )
  }

  const incompleteColor:AcceptableColorValue = 'grey.lighter'
  const completedColor:AcceptableColorValue = 'primary'

  const step1Color = completedColor
  const step2Color = props.hasStartedRoute ? completedColor : incompleteColor
  const step3Color = props.isDelivered ? completedColor : incompleteColor

  return (
    <Container.Base
      className="progress-bar"
      $width="100%"
      $paddingMedia={{mobile: '1rem'}}
      >
      <Container.Grid
        $borderRadius="3rem"
        $overflow="hidden"
        $borderColor="white"
        $borderWidth="2px"
        $borderStyle="solid"
        $gridTemplateColumns="1fr 8.56px 1fr 8.56px 1fr"
        $gridTemplateRows="2rem"
      >
        <ProgressBarChunk className="step-1-bar" backgroundColor={step1Color} />
        <ProgressDivider
          fillLeft={step1Color}
          fillCenter="white"
          fillRight={step2Color}
        />
        <ProgressBarChunk className="step-2-bar" backgroundColor={step2Color} />
        <ProgressDivider
          fillLeft={step2Color}
          fillCenter="white"
          fillRight={step3Color}
        />
        <ProgressBarChunk className="step-3-bar" backgroundColor={step3Color} />
      </Container.Grid>
      <Spacer $height="0.8rem" />
      <Container.Grid
        $gridTemplateColumns="1fr 1fr 1fr"
        $gridTemplateColumnsMedia={{mobile: '1fr'}}
        $gridGap="8.56px"
        $justifyItems="center"
      >
        <Container.Base
          className="step-1-label"
          $color="grey.dark"
          $textAlign="center"
          $displayMedia={{
            mobile: !!(!props.hasStartedRoute && !props.isDelivered)
              ? 'block'
              : 'none'
          }}
        >
          <Text.Subtitle2 $color={props.wasPickedUp ? 'black' : 'inherit'}>
            {props.wasPickedUp ? 'Pickup Complete' : 'Pickup In Progress'}
          </Text.Subtitle2>
          {wasPickedUp(props) ? <Text.Caption>{props.pickedUpAt.toString()}</Text.Caption> : null}
          <Text.Caption>{props.pickupLocation}</Text.Caption>
        </Container.Base>
        <Container.Base
          className="step-2-label"
          $color="grey.dark"
          $textAlign="center"
          $displayMedia={{
            mobile: !!(props.hasStartedRoute && !props.isDelivered)
              ? 'block'
              : 'none'
          }}
        >
          <Text.Subtitle2 $color={props.hasStartedRoute ? 'black' : 'inherit'}>Delivery In Progress</Text.Subtitle2>
          {hasStartedRoute(props) ? <Text.Caption>{props.startedRouteAt.toString()}</Text.Caption> : null}
        </Container.Base>
        <Container.Base
          className="step-3-label"
          $color="grey.dark"
          $textAlign="center"
          $displayMedia={{
            mobile: !!(props.isDelivered)
              ? 'block'
              : 'none'
          }}
        >
          <Text.Subtitle2 $color={props.isDelivered ? 'black' : 'inherit'}>
            {props.isDelivered ? 'Delivered' : 'Delivery'}
          </Text.Subtitle2>
          <Text.Caption>
            { isDelivered(props) ? 
              props.deliveredAt.toString() : 
              `Estimated ${props.deliveryDeadline.toDeadlineString()}`
            }
          </Text.Caption>
          <Text.Caption>{props.deliveryLocation}</Text.Caption>
        </Container.Base>
      </Container.Grid>
    </Container.Base>
  )
}

const ProgressBarChunk: FC<{
  className: string
  backgroundColor: AcceptableColorValue
}> = props => (
  <Container.Position $position="relative" $zIndex="-1" $width="calc(100% + 2px)" $left="-1px">
    <Container.Base className={props.className} $height="100%" $backgroundColor={props.backgroundColor} />
  </Container.Position>
)

export default TrackingProgressBar