import React, { FC, useCallback, useState, ChangeEventHandler, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AccessibleIdentity } from 'data/identities'
import { Container, Image, Text, Box, Icon } from 'roadie-ui'
import styled from 'styled-components'

const AvailableIdentity:FC<{identity:AccessibleIdentity, isCurrent:boolean}> = props => {
  const { identity, isCurrent } = props
  const handleSwitchIdentity = useCallback(() => {
    
  }, [])
  return (
    <Box.Border
      $borderTopColor="grey.lighter"
      $borderTopStyle="solid"
      $borderTopWidth="1px"
    >
      <Container.Grid
        $gridTemplateColumns="4rem auto 1.5rem"
        $gridGap=".8rem"
        $alignItems="center"
        $padding=".5rem 1.6rem"
        $width="25rem"
        $cursor="pointer"
        onClick={handleSwitchIdentity}
        $backgroundColorHover="grey.lighter@.3"
      >
        <Container.Base
          $borderRadius="100%"
          $borderColor="grey.lighter"
          $borderStyle="solid"
          $borderWidth="1px"
          $width="4rem"
          $height="4rem"
          $overflow="hidden"
        >
          <Image
            $width="100%"
            $height="100%"
            src={identity.avatar_url}
          />
        </Container.Base>
        <Container.Base $overflow="hidden">
          <Text.Body2
            $color="black@medium"
            $overflow="hidden"
            $textOverflow="ellipsis"
            $whiteSpace="nowrap"
          >
            {identity.preferred_name}
          </Text.Body2>
          <Text.Body2
            $color="grey"
            $overflow="hidden"
            $textOverflow="ellipsis"
            $whiteSpace="nowrap"
          >
            {identity.email}
          </Text.Body2>
        </Container.Base>
        {isCurrent ? <Icon.Checkmark $color="grey" /> : <span />}
      </Container.Grid>
    </Box.Border>
  )
}

// TODO should we have a RoadieUI element for this (non form input)?
const Input = styled.input`
  width: 100%;
  border: none;
  padding: 1rem;
  outline: none;
`

const IdentitiesAvailable:FC<{}> = () => {
  // @ts-expect-error typify redux
  const available:AccessibleIdentity[] = useSelector(state => state.identities.available)
  // @ts-expect-error typify redux
  const profileId:number = useSelector(state => state.profile.id)
  const [filter, setFilter] = useState("")

  const handleFilter:ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setFilter(event.target.value)
  }, [])

  const filteredIdentities = useMemo(() => {
    if (filter) {
      return available.filter(identity => {
        if (identity.email.toLowerCase().includes(filter.toLowerCase())) {
          return true
        }
        if (identity.preferred_name.toLowerCase().includes(filter.toLowerCase())) {
          return true
        }
        return false
      })
    } else {
      return available
    }
  }, [filter, available])

  const sortOrder = useCallback((a:AccessibleIdentity, b:AccessibleIdentity) => {
    if (a.id === profileId) {
      return -1
    } else if (b.id === profileId) {
      return 1
    } else {
      return a.preferred_name.localeCompare(b.preferred_name)
    }
  }, [profileId])

  return (
    <Container.Base $width="25rem">
      {available.length > 4 ? <Input onChange={handleFilter} placeholder="search" value={filter} /> : null }
      <Container.Base $maxHeight="25rem" $overflow="scroll">
        {filteredIdentities.sort(sortOrder).map(identity => (
          <AvailableIdentity
            key={identity.guid}
            identity={identity}
            isCurrent={identity.id === profileId}
          />
        ))}
      </Container.Base>
    </Container.Base>
  )
}

export default IdentitiesAvailable