export const MAX_PHOTO_SIZE_MB = 19

export const MAX_PHOTO_SIZE_BYTES = MAX_PHOTO_SIZE_MB * 1024 * 1024

export const CAG_TYPES = {
  TYPE_CREATE: 'CREATE',
  TYPE_EDIT: 'EDIT',
  TYPE_REPOST: 'REPOST',
}

export const TRAINING_CERTS = {
  DRIVER: {
    PROP22: 'prop22_training',
    ONBOARDING: 'basic_driver',
    TRAILER_HITCH: 'trailer_hitch'
  }
}

export const SHIPMENT_TYPE_UNSPECIFIED = 'Unspecified'

export const SUPPORT_PHONE_NUMBER = '4707859787'
export const CREATION_SOURCE_APPLICATION = 'web'

export const CREATION_CONTEXT = {
  MANUAL: 'manual',
  BULK: 'bulk',
  REPOST: 'repost',
}

export const GIGS_PER_PAGE = 25