import { createGlobalStyle, css } from 'styled-components/macro'
import { normalize } from 'polished'
import link from 'styles/mixins/link'
import { globalStyleSelector } from 'selectors'
import { connect } from 'react-redux'
import { parsers } from 'roadie-ui'

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  :root {
    font-size: 10px;
  }

  #hovercard {
    position: absolute;
    top: 0;
    z-index: 9999;
  }

  #tooltip {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    z-index: 999999999;
  }

  .grecaptcha-badge { 
    visibility: hidden;
  }

  html {
    background: ${props => props.theme.colors.background};
    font-weight: 300;
    font-family: "proxima-nova","Verdana","Gill Sans","Lucida Grande","Arial","Helvetica",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    font-weight: 400;
    
    * {
      box-sizing: border-box;
    }

    ${props => props.modalOpen && css`
      overflow: hidden;
    `}
  }

  body {
    font-size: 1.8rem;
    color: ${props => props.theme.colors.greyDark};
    width: 100%;

    ${props => props.sidebarOpen && css`
      position: fixed;
      overflow: hidden;
    `}
  }

  h1 {
    font-weight: 900;
    font-size: 3.6rem;
    line-height: 4.3rem;
    color: ${props => props.theme.colors.greyDarker};
    margin: 0;
    padding: 0;
  }

  h3 {
    font-weight: 700;
    font-size: 1.6rem;
    color: ${props => props.theme.colors.greyDark};
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    padding-bottom: 0.8rem;
  }

  h4 {
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${props => props.theme.colors.greyDark};
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    margin: 0;
  }

  p {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding: 0;
    margin: 0;
  }

  a {
    ${link()}
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .introjs-tooltip.roadie-introjs-tooltip {
    background-color: ${parsers.color('white')};

    .introjs-skipbutton {
      font-size: 3rem;
      color: ${parsers.color('grey')};
      line-height: .5rem;
      width: 1rem;
      margin-right: -7px;

      &:hover {
        color:  ${parsers.color('primary.darker')};
      }
    }

    .introjs-tooltiptext {
      color: ${parsers.color('grey.darker')};
    }

    .introjs-arrow {
      &.left {
        border-right-color: ${parsers.color('white')};
      }
      &.right {
        border-left-color: ${parsers.color('white')};
      }
      &.bottom {
        border-top-color: ${parsers.color('white')};
      }
      &.top {
        border-bottom-color: ${parsers.color('white')};
      }
    }

    .introjs-bullets ul li a {
      background: ${parsers.color('primary.light')};

      &.active {
        background: ${parsers.color('primary')};
      }
    }

    .introjs-tooltipbuttons {
      border-top: 1px solid ${parsers.color('grey.lighter')};

      .introjs-button {
        background-color: ${parsers.color('primary')};
        color: ${parsers.color('white')};
        text-shadow: none;
        border: none;
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 500;
        font-family: "proxima-nova","Verdana","Gill Sans","Lucida Grande","Arial","Helvetica",sans-serif;
        letter-spacing: 0.125rem;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .introjs-helperLayer.roadie-introjs-highlight {
    box-shadow: ${parsers.color('black@.5')} 0px 0px 0px 5000px !important;
  }


  /* /////// GOOGLE AUTO COMPLETE START (ripped from old app) ////////// */
  .pac-container,
  .saved-address-list {
    border: 1px solid $teal; min-height: 200px; z-index: 2000; border-radius: 0 0 5px 5px; border-top: 0; background: ${props => props.theme.colors.greyLighter}; box-shadow: 0 2px 6px rgba(0,0,0,0.3);
    .pac-item {
      font-size: 1.3rem; font-weight: 300; color: ${props => props.theme.colors.greyDark}; padding: 5px 15px 4px; border: 0;
      .pac-item-query {
        font-size: 1.6rem; font-weight: 300; color: ${props => props.theme.colors.greyDarker};
        .pac-matched { font-size: 1.6rem; color: ${props => props.theme.colors.greyDarker}; font-weight: 700; }
        &:after { content: ','; }
      }
      .pac-icon { display: none; }
      &.pac-item-selected { font-size: 1.6rem; color: ${props => props.theme.colors.greyDarker}; }
      &.pac-item-selected,
      &.pac-item-selected:hover {
        background: ${props => props.theme.colors.primaryBase} !important; color: ${props => props.theme.colors.white} !important; font-weight: 500;
        .pac-item-query,
        .pac-item-query .pac-matched {
          color: ${props => props.theme.colors.white} !important;
        }
      }
      &:hover {
        background: ${props => props.theme.colors.primaryBase}; color: ${props => props.theme.colors.white}; font-weight: 500; cursor: hand; cursor: pointer;
        .pac-item-query,
        .pac-item-query .pac-matched { color: ${props => props.theme.colors.white}; }
      }
    }
  }
  .pac-container.pac-logo:after {
    display: none !important;
  }
  /* /////// GOOGLE AUTO COMPLETE END (ripped from old app) ////////// */
`

export default connect(globalStyleSelector)(GlobalStyle)