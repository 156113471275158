import { createSelector } from 'reselect'

const simpleSelector = sel => createSelector([sel], x => x)

const _mfa = simpleSelector(state => state.mfa)
const _profile = simpleSelector(state => state.profile)
const _authenticityVerificationState = simpleSelector(state => state.authenticityVerificationState)
const _config = simpleSelector(state => state.config)
const _driving = simpleSelector(state => state.driving)
const _taxes = simpleSelector(state => state.taxes)
const _gigs = simpleSelector(state => state.gigs)
const _createGig = simpleSelector(state => state.createGig)
const _cag = simpleSelector(state => state.cag)
const _creditBalance = simpleSelector(state => state.creditBalance)
const _notificationSettings = simpleSelector(state => state.notificationSettings)
const _earningsStatement = simpleSelector(state => state.earningsStatement)

export const globalStyleSelector = createSelector(
  [_config], (config) => ({ 
    sidebarOpen: !!config.sidebarOpen,
    modalOpen: !!config.modalOpen,
  })
)

export const appRootPathSelector = createSelector(
  [_config], config => ({ appRootPath: config.appRootPath })
)

export const appRootPathAndProfileSelector = createSelector(
  [_config, _profile], (config, profile) => ({ appRootPath: config.appRootPath, profile })
)

export const mediaSizeSelector = createSelector(
  [_config], config => ({ mediaSize: config.mediaSize })
)

export const gigHistorySelector = createSelector(
  [_driving, _earningsStatement], (driving, earningsStatement) => {

    const lastFetchedPage = driving.history && Object.keys(driving.history).map(Number).sort((a, b) => b - a)[0]
    const hasMoreHistory = lastFetchedPage && driving.history[lastFetchedPage].length === 25
    const nextHistoryPage = hasMoreHistory ? lastFetchedPage + 1 : null

    return {
      summary: driving.summary,
      history: driving.history,
      nextHistoryPage,
      earningsStatement,
    }
  }
)

export const taxSelector = createSelector(
  [_taxes,  _profile], (taxes, profile) => ({ taxes, profile })
)

export const driverWalkthroughSelector = createSelector(
  [_config, _profile], (config, profile) => ({ profile, isWebview: config.isWebview })
)

export const basicInfoSelector = createSelector(
  [_config, _profile], (config, profile) => ({ profile, isWebview: config.isWebview })
)


export const passwordFormSelector = createSelector(
  [_config, _profile], (config, profile) => ({ profile, isWebview: config.isWebview })
)

export const termsSelector = createSelector(
  [_config, _profile], (config, profile) => ({ profile, isWebview: config.isWebview })
)

export const isWebviewSelector = createSelector(
  [_config], (config) => ({
    isWebview: config.isWebview
  })
)

export const pwaInstallSelector = createSelector(
  [_config], (config) => ({
    isWebview: config.isWebview,
    canInstallPWA: config.canInstallPWA,
  })
)

export const sidebarOpenSelector = createSelector(
  [_config], config => ({ sidebarOpen: config.sidebarOpen })
)

export const flashSelector = createSelector(
  [_config], config => ({ flash: config.flashStack.length ? config.flashStack[config.flashStack.length-1] : null, modalOpen: !!config.modalOpen, })
)

export const modalOpenSelector = createSelector(
  [_config], config => ({ modalOpen: !!config.modalOpen, })
)

export const profileSelector = createSelector(
  [_profile], (profile) => ({ profile })
)

export const profileWithNotificationsSelector = createSelector(
  [_profile, _notificationSettings], (profile, notificationSettings) => ({ profile, notificationSettings })
)

export const signOutSelector = createSelector(
  [_profile, _config], (profile, config) => ({
    profile,
    appRootPath: config.appRootPath,
  })
)

export const historyWatcherSelector = createSelector(
  [_profile, _config], (profile, config) => ({
    profile,
    appRootPath: config.appRootPath,
  })
)

export function senderNeeds(mfa, profile) {
  if (mfa.guid) {
    return 'mfa'
  }
  if (!profile) {
    return 'user'
  }
  if (!profile.phone_number_verified) {
    return 'verify-phone'
  }
  if (profile.tos_update_required) {
    return 'terms'
  }
  return null
}

export const sendNeedsSelector = createSelector(
  [_mfa, _profile, _config], (mfa, profile, config) => {
    const registered = profile ? profile.certifications.includes('basic_sender') : false

    return {
      needs: senderNeeds(mfa, profile),
      registered,
      flash: config.flash,
    }
  }
)

export function driverNeeds(mfa, profile, isWebview, state) {
  console.log('driver needs c')
  if (mfa.guid) {
    return 'mfa'
  }
  if (!profile) {
    return 'user'
  }
  if (!profile.phone_number_verified) {
    return 'verify-phone'
  }
  if (profile.tos_update_required || profile.accepts_arbitration === null) {
    return 'terms'
  }
  if (!profile.hashed_date_of_birth) {
    return 'basic-info'
  }
  if (!profile.drivers_license || !profile.drivers_license.hashed_number || !profile.vehicles.length) {
    return 'driving-info'
  }
  if (profile.status === 'pending') {
    return 'blocked-message'
  }
  if (!isWebview && !profile.has_ssn) {
    console.log('go-mobile', { isWebview, profile, state })
    return 'go-mobile'
  }
  if (isWebview && profile.status === 'onboarding' && (state === 'unsubmitted' || state === 'reattempt') && !profile.certifications.includes('basic_driver')) {
    console.log('verify-identity', { isWebview, profile, state })
    return 'verify-identity'
  }
  if (!profile.has_ssn) {
    return 'consumer-report'
  }
  if (!profile.has_bank_account) {
    return 'bank-info'
  }
  if (!profile.has_avatar) {
    return 'profile-picture'
  }
  if (!profile.certifications.includes('basic_driver')) {
    return 'onboarding'
  }
  
  return null
}

export const driveNeedsSelector = createSelector(
  [_mfa, _profile, _config, _authenticityVerificationState], (mfa, profile, config, state) => {
    
    const { wasSigningUp, isWebview } = config

    const registered = profile ? profile.certifications.includes('basic_driver') : false

    const isW = isWebview || window.location.pathname.startsWith("/webview")

    return {
      needs: driverNeeds(mfa, profile, isW, state),
      registered,
      wasSigningUp,
      isWebview,
      flash: config.flash,
    }
  }
)

export const driveSidebarSelector = createSelector(
  [_profile, _config, _earningsStatement], (profile, config, earningsStatement) => ({
    profile,
    earningsStatement,
    appRootPath: config.appRootPath,
  })
)

export const driveDashboardSelector = createSelector(
  [_profile, _config], (profile, config) => ({
    profile,
    badges: config.badges,
  })
)

export const sendSidebarSelector = createSelector(
  [_profile, _config, _creditBalance], (profile, config, creditBalance) => ({
    profile,
    appRootPath: config.appRootPath,
    credits: creditBalance.credits,
  })
)

export const cagPaymentSelector = createSelector(
  [_profile, _creditBalance, _config], (profile, creditBalance, config) => ({
    profile,
    credits: creditBalance.credits,
    mediaSize: config.mediaSize,
  })
)

export const cagStepSelector = createSelector(
  [_config, _profile, _creditBalance, _cag], (config, profile, creditBalance, cag) => ({
    appRootPath: config.appRootPath,
    flash: config.flash,
    credits: creditBalance.credits,
    mediaSize: config.mediaSize,
    allowedCerts: cag.allowed_certs,
    minWindow: cag.min_delivery_window,
    profile,
  })
)

export const sendPaymentPageSelector = createSelector(
  [_profile, _creditBalance], (profile, creditBalance) => ({
    profile,
    credits: creditBalance.credits,
  })
)

export const createGigSelector = createSelector(
  [_createGig, _profile, _config], (createGig, profile, config) => ({
    gigData: createGig,
    cagHints: config.cagHints,
    profile,
   })
)

export const editGigSelector = gigId => createSelector(
  [_gigs], gigs => ({ editData: (gigs.details[gigId] && gigs.template) ? { gig: gigs.details[gigId], template: gigs.template } : null })
)

export const gigTemplateSelector = createSelector(
  [_gigs], gigs => ({ gigTemplate: gigs.template })
)

export const gigTemplateExpirationSelector = createSelector(
  [_gigs], gigs => ({ gigTemplateExpiration: gigs.template_expiration })
)

export const gigDetailsSelector = gigId => createSelector(
  [_gigs], gigs => ({ gig: gigs.details[gigId] })
)
